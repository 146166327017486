import React from 'react';
import { getAppUrl, getBlogUrl, getContentSiteUrl, getMonolithUrl, getSubmissionUrl } from 'src/config';
import SellerPageHeader, {
    contactUsFactory,
    LinkItem,
    loggedOutMenuItemsFactory,
    menuItemsFactory,
} from '@worthy-npm/worthy-common-ui-components/components/SellerPageHeader';
import worthyShineTheme from '@worthy-npm/worthy-common-ui-components/theme/worthyShineTheme';
import { useAppDispatch, useAppSelector } from 'src/store';
import { getItemCount } from 'src/store/userSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { setRoute } from 'src/store/sharedSlice';
import { redirectTo } from 'src/helpers/common.ts';
import { Box, Button, Divider, Icon, ListItemIcon, ListItemText, MenuItem, styled } from '@worthy-npm/worthy-common-ui-components';

const StyledMenuItems = styled(MenuItem)(({ theme }) => ({
    color: theme.palette.text.primary,
    '& .MuiListItemIcon-root': {
        color: theme.palette.text.primary,
    },
}));

function CustomLoggedInMenu() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    return (
      <Box p={1} color="text.primary" width={200}>
        <StyledMenuItems onClick={()=> {
            dispatch(setRoute('/my-items'));
            navigate('/my-items');
        }}>
          <ListItemIcon>
            <Icon.ShoppingBasket fontSize="small" />
          </ListItemIcon>
          <ListItemText>My items</ListItemText>
        </StyledMenuItems>
        <StyledMenuItems onClick={() => redirectTo(`${getAppUrl()}/refer_a_friend`)}>
          <ListItemIcon>
            <Icon.Reward fontSize="small" />
          </ListItemIcon>
          <ListItemText>Refer a friend</ListItemText>
        </StyledMenuItems>
        <Divider />
        <StyledMenuItems>
          <ListItemIcon>
            <Icon.LogOut fontSize="small" />
          </ListItemIcon>
          <ListItemText>Log out</ListItemText>
        </StyledMenuItems>
        <Box p={1}>
          <Button fullWidth variant="contained" color="highlight" size="small" disableElevation onClick={()=> redirectTo(getSubmissionUrl())}>
            Start selling
          </Button>
        </Box>
      </Box>
    );
}

const Header = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const itemsCount = useAppSelector(getItemCount);
    const contactUsData = contactUsFactory('(888) 222-0208');
    const loggedOutMenuItemsData = loggedOutMenuItemsFactory(getAppUrl());

    const loggedInMenuItemsData = [{
        key:'myItems',
        title:'My Items',
        link:'/my-items',
    },{
        key: 'refer-a-friend',
        title: 'Refer a Friend',
        link: '/refer-a-friend',
    },{
        key: 'logout',
        title: 'Logout',
        link: '/logout',}]
    const menuItemsData = menuItemsFactory({
        appBaseUrl: getAppUrl(),
        blogBaseUrl: getBlogUrl(),
        monolithBaseUrl: getMonolithUrl(),
    });

    const homeLinks: Record<string, string> = {
        howItWorks: `${getContentSiteUrl()}/how-it-works/`,
        home: getContentSiteUrl(),
    };

    const onItemClick = (e: React.SyntheticEvent, payload: { key: string } & Partial<LinkItem>) => {
        const link = String(payload?.link);
        if (payload.key === 'menuOpen') {
            return;
        }

        if (payload.key.includes('root') && payload.link) {
            if (location.pathname === payload.link) return;
            dispatch(setRoute(payload.link));
            navigate(payload.link);
            return;
        }

        if (Object.keys(homeLinks).includes(payload.key)) {
            redirectTo(homeLinks[payload.key]);
        } else {
            redirectTo(link);
        }
    };

    return (
        <SellerPageHeader
            contactUsData={contactUsData}
            loggedInMenuItemsData={loggedInMenuItemsData}
            loggedOutMenuItemsData={loggedOutMenuItemsData}
            menuItemsData={menuItemsData}
            loginState="loggedIn"
            itemsCount={itemsCount}
            onItemClick={onItemClick}
            disableFadeUp={true}
            CustomLoggedInMenu={CustomLoggedInMenu}
            appBarProps={{
                position: 'sticky',
                sx: {
                    color: worthyShineTheme.palette.text.primary,
                    backgroundColor: worthyShineTheme.palette.background.default,
                    boxShadow: `0px 1.5px 0px 0px ${worthyShineTheme.palette.divider}`,
                },
            }}
            itemsCountProps={{
                sx: {
                    display: 'none',
                },
            }}
        />
    );
};

export default Header;
